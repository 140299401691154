<template>
  <div>
    <table class="table my-4" id="playerTable">
      <thead>
        <tr>
          <th style="text-align: left" scope="col">Player Name</th>
          <th scope="col">
            Forecasted Goals
            <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
          </th>
          <th scope="col">
            Forecasted Assists
            <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
          </th>
          <th scope="col">
            Forecasted Points
            <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
          </th>
          <th scope="col">Boost</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="player in sortedPlayers" :key="player['player-key']">
          <td align="left"><router-link :to="`/player-forecast/${player['player-key']}`" class="text-decoration-none">{{ player["player-name"] }}</router-link></td>
          <td>{{ player["guessing-score"]["score"].toFixed(2) }}</td>
          <td>{{getForecastedAssistValue(player).toFixed(2)}}</td>
          <td>{{(getForecastedAssistValue(player) + player["guessing-score"]["score"]).toFixed(2)}}</td>
          <td>
            <a
              class="link-primary"
              style="text-decoration: none"
              role="button"
              @click="showModal(player)"
            >
              <span> {{ getBoost(player) }}% </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modals -->
    <PlayerModal
      v-if="isModalVisible"
      :player="player"
      :awayTeam="awayTeam"
      :homeTeam="homeTeam"
      @close="closeModal()"
    ></PlayerModal>
  </div>
</template>

<script>
import PlayerModal from "./PlayerModal.vue";
import _ from "lodash";

export default {
  name: "PlayerTable",
  components: {
    PlayerModal,
  },

  props: ["players", "awayTeam", "homeTeam","teamTotal"],

  data() {
    return {
      isModalVisible: false,
      player: {},
    };
  },

  computed: {
    sortedPlayers() {
      return _.orderBy(this.players, function (player) {
        return player["guessing-score"]["score"];
      }).reverse();
    },
  },

  methods: {
    showModal(player) {
      this.player = player;
      this.isModalVisible = true;
    },

    getBoost(player) {
      let value = player["guessing-score"]["boost"].toFixed(1);
      let sign = this.getSign(value);

      return `${sign}${value}`;
    },

    // TODO Repeated function move to another place
    getSign(number) {
      let result = Math.sign(number);
      let mappings = {
        1: "+",
        "-1": "",
        0: "",
      };

      return mappings[result];
    },

    getForecastedAssistValue(player){
      const predicted_assist_season_percent = player['algorithms']['average']['intermediate_values']['predicted-assists-season-percent'] * 1
      return Number((predicted_assist_season_percent/100) * this.teamTotal * 1.7)
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style lang="css" scoped>
.btn-primary {
  background-color: #0d6efd !important;
}

.bi-color {
  color: grey;
}
</style>
